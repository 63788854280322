<template>
  <div v-if="liaison" class="profile-body">
    <BackHeader is-show-title is-static title="Member liaison" />
    <MemberLiaisonBox :liaison="liaison" />
  </div>
</template>

<script>
import meLiaisonQuery from '@/graphql/me/MeLiaison.query.gql';

import BackHeader from '@/components/partials/BackHeader';
import MemberLiaisonBox from '@/components/partials/MemberLiaisonBox';

export default {
  name: 'MemberLiaison',
  components: {
    BackHeader,
    MemberLiaisonBox,
  },
  metaInfo: {
    title: 'Member Liaison',
  },
  data() {
    return {
      liaison: null,
    };
  },
  apollo: {
    me: {
      query: meLiaisonQuery,
      result(result) {
        if (result.data.me.liaison) {
          this.liaison = result.data.me.liaison;
        }
      },
    },
  },
};
</script>
