<template>
  <div class="profile-body delete-account-body">
    <BackHeader is-show-title is-static title="Delete account" />

    <p>
      By entering your password below, you are agreeing to delete The Cultivist app and all the data it holds including
      your bookings and membership information. If you would like to manage your subscription, please contact the team
      <a href="mailto:membership.admin@thecultivist.com">here</a>.
    </p>

    <form @submit.prevent="handleSubmit">
      <input hidden autocomplete="username" type="text" :value="user.email" />
      <AppFormInput
        v-model="form.password"
        autocomplete="current-password"
        label="Current password"
        required
        type="password"
      />
      <button class="btn border-btn" :disabled="submitted" type="submit">
        <AppLoader v-if="submitted" :border-width="3" :margin="2" :size="20" />
        <span v-else>Delete account</span>
      </button>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BackHeader from '@/components/partials/BackHeader.vue';
import AppFormInput from '@/components/partials/elements/AppFormInput.vue';

export default {
  name: 'DeleteAccount',
  components: { AppFormInput, BackHeader },
  metaInfo: {
    title: 'Delete account',
  },
  data() {
    return {
      form: {
        password: '',
      },
      submitted: false,
    };
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    handleSubmit() {
      if (this.submitted) {
        return;
      }

      this.submitted = true;

      this.$store
        .dispatch('deleteAccount', this.form)
        .then((res) => {
          this.$toast.success(res.data.message);
          this.$router.push({ name: 'login' });
        })
        .catch((e) => {
          this.$toast.error(e.data.message);
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-account-body {
  p {
    font-size: 18px;
    line-height: 32px;
  }
  a {
    color: inherit;
    font-weight: 400;
  }
}

form {
  margin-top: 56px;

  @media (min-width: 992px) {
    padding-right: 32px;
  }

  ::v-deep {
    .input {
      label {
        font-size: 18px;
        text-transform: uppercase;
      }
      &.active label {
        font-size: 16px;
      }

      input {
        font-size: 16px;
      }
    }
  }

  button {
    max-width: 300px;
    max-height: 56px;
    padding: 16px;
    font-size: 16px;
  }
}
</style>
